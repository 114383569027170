/** {*/
/*  outline: 1px dotted red;*/
/*}*/

:root {
  /*darkest to brightest*/

  --primary-dark: #0959d1;
  --primary-main: #0669de;

  --grey2: #4f4f4f;
  --grey5: #e0e0e0;
}

body {
  font-family: sans-serif;
}

a {
  text-decoration: none;
  font-style: inherit;
}
